<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/contact.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">Contacts</h1>
              <div class="divider"></div>
              <p class="lead">
                Do you have any question? Do not hesitate to contact us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts mt-5">
      <div class="containerLarge">
        <div class="row mt-5">
          <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-5">
            <div class="border p-3">
              <p class="lead">
                <strong>Alfa Laval Italy S.r.l. • Marine Division</strong>
              </p>
              <p>
                Via De Marini 53 Torre - Shipping 9º Piano scala B - 16149 Genoa
                - Italy <br />
                Tel. switchboard: +39 010 601 01 • Fax switchboard: +39 010 601
                02 22
              </p>
              <p class="mb-0">
                <a
                  target="_blank"
                  class="textPrimary"
                  href="mailto:marinecapital.italia@alfalaval.com"
                  >marinecapital.italia@alfalaval.com</a
                >
                •
                <a
                  target="_blank"
                  class="textPrimary"
                  href="mailto:marineservice.italia@alfalaval.com"
                  >marineservice.italia@alfalaval.com</a
                >
                <br />
                <a
                  target="_blank"
                  href="http://www.alfalaval.it"
                  class="textPrimary"
                  >www.alfalaval.it</a
                >
              </p>
            </div>
          </div>
          <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="sendMsg()">
              <form class="formContacts">
                <b-form-group>
                  <b-form-radio v-model="mailType" value="new">New equipment</b-form-radio>
                  <b-form-radio v-model="mailType" value="tech">Technical assistance</b-form-radio>
                </b-form-group>
                <div v-if="prodotto" class="form-group">
                  <label for="productField">Product</label>
                    <input
                      v-model="prodotto"
                      type="text"
                      class="form-control"
                      id="productField"
                      disabled
                    />
                </div>
                <div class="form-group">
                  <label for="InputName">Full Name</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                    <input
                      v-model="name"
                      type="text"
                      class="form-control"
                      id="InputName"
                      placeholder="John Doe"
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="InputEmail">Email address</label>
                  <ValidationProvider :rules="{ required: true, email: true }" v-slot="{ errors }">
                    <input
                      v-model="email"
                      type="email"
                      class="form-control"
                      id="InputEmail"
                      placeholder="johndoe@company.com"
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="InputCompany">Company</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                    <input
                      v-model="company"
                      type="text"
                      class="form-control"
                      id="InputCompany"
                      placeholder="Company Name"
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="InputPhone">Phone <em>(optional)</em></label>
                  <input
                    v-model="phone"
                    type="text"
                    class="form-control"
                    id="InputPhone"
                    placeholder="Company Name"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="message">Message</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                    <textarea v-model="msg" class="form-control" id="message" rows="4"></textarea>
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-check">
                  <ValidationProvider :rules="{ rejectsFalse: true }" v-slot="{ errors }">
                    <input
                      v-model="formCheck"
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                  <label class="form-check-label" for="exampleCheck1"
                    >I declare that I have taken
                    <a class="linkEvidence textPrimary" href="https://www.alfalaval.com/legal/privacy-policy/" target="_blank" rel="noopener">information</a>
                    from Alfa Laval for the processing of personal data</label
                  >
                </div>
                <b-button class="btn btn-turquoise" :disabled="invalid" @click="sendMsg">Send Message</b-button>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'Email is not valid'
});

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('rejectsFalse', {
  /* eslint-disable arrow-body-style */
  validate: (value) => { return value === true; },
  message: 'This field must be checked'
});

export default {

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    mailType: 'new',
    name: '',
    email: '',
    company: '',
    phone: '',
    msg: '',
    formCheck: false,
    prodotto: null
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    },
    getProductContacts(){
      return this.$store.getters.getProductContacts;
    }
  },

  mounted(){
    if (this.getProductContacts) this.prodotto = this.getProductContacts.name + ' - ' + this.getProductContacts.size;
  },

  methods: {    
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    sendMsg(){
      const object = {};
      object.type = 'marine';
      object.mailType = this.mailType;
      object.name = this.name;
      object.email = this.email;
      object.company = this.company;
      object.phone = this.phone;
      object.msg = this.msg;

      if (this.prodotto) object.prodotto = this.prodotto;

      this.$http.post('/api/sito/send-mail', object)
        .then((resp) => {
          console.log('[Message sent]', resp);
          this.$router.push('/thanks');
        })
        .catch((err) => {
          console.log('Err on send message', err);
        });
    }
  }
};
</script>
